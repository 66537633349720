<template>
  <mobile-screen
    :header="true"
    screen-class="gray-bg icon-app1"
    v-if="preference"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="about-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_company-preferences-set-preference-help-online',
          query: { slug: helpSlug }
        }"
      >
        <template v-slot:left>
          <router-link :to="{ name: 'r_company-preference-category' }">
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ preference.name }}
        </div>
        <template
          v-slot:right
          v-if="!preferenceAutomaticSave.includes(preference.preference)"
        >
          <button @click="updatePreference()">
            <icon icon="#cx-hea1-edit-done" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <div class="company-preference-list">
      <component
        v-bind:is="preferenceComponentsMap[preference.preference]"
        :preferenceId="preference.id"
      ></component>
    </div>
  </mobile-screen>
  <router-view></router-view>
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import httpServiceAuth from "@/services/http-service";
import { errorHandler } from "@/services/error-handler";
import { apiEndpoints } from "@/services/constants";
import { preferenceCategoryIds } from "@/services/preference-options";
import {
  preferenceAutomaticSave,
  preferenceComponentsMap,
  preferenceSetIdsHelpOnline
} from "@/services/preference-options";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "SetPreference",
  mixins: [helpOnlineMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    StartUpPage: defineAsyncComponent(() =>
      import("@/components/company-preferences/landing-page/StartUpPage")
    ),
    DefaultLocation: defineAsyncComponent(() =>
      import("@/components/company-preferences/location/DefaultLocation")
    ),
    FirstDayOfWeek: defineAsyncComponent(() =>
      import("@/components/company-preferences/calendar/FirstDayOfWeek")
    ),
    WeekendDays: defineAsyncComponent(() =>
      import("@/components/company-preferences/calendar/WeekendDays")
    ),
    DisplayWeekend: defineAsyncComponent(() =>
      import("@/components/company-preferences/calendar/DisplayWeekend")
    ),
    MorningFrom: defineAsyncComponent(() =>
      import("@/components/company-preferences/calendar/MorningFrom")
    ),
    AfternoonFrom: defineAsyncComponent(() =>
      import("@/components/company-preferences/calendar/AfternoonFrom")
    ),
    EveningFrom: defineAsyncComponent(() =>
      import("@/components/company-preferences/calendar/EveningFrom")
    ),
    NightFrom: defineAsyncComponent(() =>
      import("@/components/company-preferences/calendar/NightFrom")
    ),
    DateFormat: defineAsyncComponent(() =>
      import("@/components/company-preferences/date-time/DateFormat")
    ),
    TimeFormat: defineAsyncComponent(() =>
      import("@/components/company-preferences/date-time/TimeFormat")
    ),
    DateTimeMode: defineAsyncComponent(() =>
      import("@/components/company-preferences/date-time/DateTimeMode")
    ),
    DefaultSlot: defineAsyncComponent(() =>
      import("@/components/company-preferences/slots/DefaultSlot")
    ),
    AvailableSlots: defineAsyncComponent(() =>
      import("@/components/company-preferences/slots/AvailableSlots")
    ),
    HighOccupancyPercentage: defineAsyncComponent(() =>
      import("@/components/company-preferences/colors/HighOccupancyPercentage")
    ),
    AverageOccupancyPercentage: defineAsyncComponent(() =>
      import(
        "@/components/company-preferences/colors/AverageOccupancyPercentage"
      )
    ),
    HighOccupancyColor: defineAsyncComponent(() =>
      import("@/components/company-preferences/colors/HighOccupancyColor")
    ),
    AverageOccupancyColor: defineAsyncComponent(() =>
      import("@/components/company-preferences/colors/AverageOccupancyColor")
    ),
    LowOccupancyColor: defineAsyncComponent(() =>
      import("@/components/company-preferences/colors/LowOccupancyColor")
    ),
    DefaultMeetingTitle: defineAsyncComponent(() =>
      import("@/components/company-preferences/booking/DefaultMeetingTitle")
    )
  },
  data() {
    return {
      preferenceAutomaticSave: preferenceAutomaticSave,
      preferenceComponentsMap: preferenceComponentsMap,
      preferenceSetIdsHelpOnline: preferenceSetIdsHelpOnline,
      preference: null,
      helpSlug: null
    };
  },
  computed: {
    ...mapState("companyPreferences", ["preferences", "preferenceUpdateData"])
  },
  methods: {
    ...mapActions("companyPreferences", [
      "setPreferenceUpdateData",
      "setPreferences",
      "getCompanyPreferences"
    ]),
    getPreference() {
      if (this.preferences && this.preferences.length) {
        this.preference = this.preferences.find(
          preference => preference.id == this.$route.params.preferenceId
        );
        this.setData();
        this.helpSlug = this.preferenceSetIdsHelpOnline[
          this.preference.preference
        ];
        this.getHelpOnline(this.helpSlug);
      }
    },
    setData(updatePreference) {
      const preferenceUpdateData = {
        values: this.preference.preference_values
      };
      this.setPreferenceUpdateData(preferenceUpdateData);
      if (updatePreference) {
        this.updatePreference();
      }
    },
    updatePreference() {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .put(
          `${apiEndpoints.company.companyPreferences}/${this.preference.id}`,
          this.preferenceUpdateData
        )
        .then(response => {
          const preferencesUpdateData = [...this.preferences];
          const objIndex = preferencesUpdateData.findIndex(
            obj => obj.id == response.data.data.id
          );
          preferencesUpdateData[objIndex] = response.data.data;
          this.setPreferences(preferencesUpdateData);
          this.$store.commit("loader/setScreenLoading", false, { root: true });
          this.$router.push({ name: "r_company-preference-category" });
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          if (
            Number(this.$route.params.categoryId) ===
            preferenceCategoryIds.slots
          ) {
            this.getCompanyPreferences(preferenceCategoryIds.slots);
          }
        });
    }
  },
  created() {
    this.getPreference();
  },
  watch: {
    "$route.params.preferenceId"(value) {
      if (value) {
        this.getPreference();
      }
    },
    preferences() {
      this.getPreference();
    }
  }
};
</script>
